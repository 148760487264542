<!-- 卖卡记录 -->
<template>
  <div class="selCardRecord">

    <div class="cardTypeFather">
      <div class="cardType">
        <span class="type">卡类:</span>
        <p style="    width: 84%;">

          <!-- <span>电商礼品卡</span> -->
          <select class="selectCardType" name="" id="" v-model="cardTypeObj.gc_type" @change="changeCardType">
            <option v-for="item in cardTypeList" :value="item.value" :key="item.value">
              {{ item.text }}
            </option>
          </select>
        </p>

      </div>
      <div class="cardType">
        <span class="type">卡种:</span>
        <p style="    width: 84%;">

          <span v-for="(item, index) in cardNameist" :key="index"
            :class="{ 'cardTable': true, 'selectTable': reqcardRecordObj.gc_id == item.gc_id }"
            @click="changeCard(item.gc_id)">{{ item.gc_name }}</span>

        </p>
      </div>
      <p style="text-align:right;margin:5px 10px 5px 10px;position: fixed;right: 7px;"> <button @click="show = true"
          style="border: 1px solid #f83130;
          border-radius: 3px;
    background: #f83130;
    color: #fff;">订单过滤</button></p>
      <van-popup v-model:show="show" position="top" :style="{ height: '30%' }">
        <p style="text-align: center">筛选过滤</p>
        <!-- <p class="filterItem"> -->

        <van-cell title="选择日期区间:" :value="filterData.start_time + '至' + filterData.end_time" @click="timeShow = true" />

        <!-- </p> -->
        <p class="filterItem"><span>订单状态:</span><select v-model="reqcardRecordObj.usr_status">
            <option v-for="item in usr_statusList" :key="item.value" :value="item.value">{{ item.label }}</option>
          </select>
        </p>
        <p style="text-align: center">
          <van-button type="primary" @click="filterSearch">确定</van-button>
        </p>
      </van-popup>
    </div>

    <van-calendar v-model:show="timeShow" type="range" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" />
    <div class="cardRecord">

      <van-list v-model:loading="state.loading" :finished="state.finished" finished-text="没有更多了" @load="onLoad">
        <div class="record" v-for="(item, index) in state.list" :key="index">
          <p>
            <span class="recordg_cost">面值 : {{ item.g_cost }}元</span>
            <span class="recordg_cost">卡号 : {{ item.card_num }}</span>
            <span class="recordg_cost">卡密 : {{ item.card_data }}</span>
            <span class="recordg_cost">时间 : {{ item.usr_addtime }}</span>
            <span class="recordg_cost timeColor">备注 : {{ item.usr_remark }}</span>
          </p>
          <p :style="{ color: item.usr_status == 0 ? '#0882ea' : item.usr_status == 1 ? '#04d932' : '#ff3e13' }">
            {{ item.usr_status == 0 ? '审核中' : item.usr_status == 1 ? '已通过' : '已驳回' }}
          </p>
        </div>
      </van-list>

    </div>

  </div>
</template>

<script>
import G_time from "../../tools/time";
export default {
  data() {
    return {
      show: false,
      timeShow: false,
      minDate: new Date(2021, 12, 1),
      maxDate: new Date(),
      state: {
        list: [],
        loading: false,
        finished: false,
        refreshing: false,
      },
      isPullRefresh: false,
      rocordTotal: 2,
      cardTypeList: [
        {
          value: 3,
          text: "电商礼品卡",
        },
        {
          value: 1,
          text: "油卡",
        },
        {
          value: 2,
          text: "话费卡",
        },

        {
          value: 4,
          text: "旅游卡",
        },
        {
          value: 5,
          text: "商超百货卡",
        },
        {
          value: 6,
          text: "餐饮服务",
        },
      ],
      cardNameist: [],
      cardTypeObj: {
        gc_type: 3,
        page: 1,
        limitL: 100,
      },
      filterData: {
        start_time: "",
        end_time: "",
      },
      reqcardRecordObj: {
        gc_id: 2,
        page: 1,
        limit: 5,
        usr_status: "",
      },
      usr_statusList: [
        {
          label: "审核中",
          value: 0,
        },
        {
          label: "已通过",
          value: 1,
        },
        {
          label: "已驳回",
          value: 2,
        },
        // {
        //   label: "未激活",
        //   value: 3,
        // },
        {
          label: "所有状态",
          value: "",
        },
      ],
    };
  },

  components: {},

  created() {
    this.getCardTypeList();
  },

  methods: {
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    filterSearch() {
      this.state.list = [];
      this.state.loading = true;
      this.show = false;
      this.reqcardRecordObj.page = 1;
      this.getRecordList();
    },
    onConfirm(values) {
      const [start, end] = values;
      // console.log(values);
      this.filterData.start_time = this.formatDate(start);
      this.filterData.end_time = this.formatDate(end);
      this.reqcardRecordObj.start_time = this.formatDate(start) + " 00:00:01";
      this.reqcardRecordObj.end_time = this.formatDate(end) + " 23:59:59";
      console.log(this.filterData);
      this.timeShow = false;
    },
    onLoad() {
      console.log("上拉加载");
      if (this.isPullRefresh) {
        this.getRecordList();
      }
    },
    // 切换卡类型
    changeCardType(val) {
      this.cardNameist = [];
      console.log(this.cardTypeObj);
      this.state.list = [];
      this.reqcardRecordObj.page = 1;
      this.getCardTypeList();
    },
    // 切换卡种
    changeCard(value) {
      this.reqcardRecordObj.gc_id = value;
      this.reqcardRecordObj.page = 1;
      this.isPullRefresh = false;
      this.state.finished = false;
      this.state.list = [];
      this.getRecordList();
    },
    // 获取卡种
    getCardTypeList() {
      console.log(this.cardTypeObj);
      this.$axios
        .get("/api/shop/getGoodsClassList", {
          params: this.cardTypeObj,
        })
        .then((res) => {
          console.log("卡种列表", res);
          if (res.code == "000") {
            this.cardNameist = res.data;
            this.reqcardRecordObj.gc_id = this.cardNameist[0].gc_id;
            this.getRecordList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取卖卡列表
    getRecordList() {
      console.log(this.reqcardRecordObj);
      this.$axios
        .get("/api/shop/getSellRecord", {
          params: this.reqcardRecordObj,
        })
        .then((res) => {
          if (res.code == "000") {
            this.state.loading = false;
            for (let i = 0; i < res.data.rows.length; i++) {
              res.data.rows[i].usr_addtime = G_time.ts2Date(
                res.data.rows[i].usr_addtime
              );
            }
            this.state.list = this.state.list.concat(res.data.rows);
            this.rocordTotal = res.data.total;
            console.log(this.state.list.length, this.rocordTotal);
            this.reqcardRecordObj.page = this.reqcardRecordObj.page + 1;
            if (this.state.list.length >= this.rocordTotal) {
              this.state.finished = true;
            }
            this.isPullRefresh = true;
          }
          console.log("卖卡记录", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang='scss'>
.selCardRecord {
  font-size: 15px;
  margin-left: 10px;
  margin-top: 43px;

  .cardTypeFather {
    // position: fixed;
    z-index: 999;
    // top: 40px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #f83130;
    margin-right: 10px;
  }

  .cardType {
    display: flex;
    // justify-content: space-between;
    width: 96%;
    // height: 55px;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    padding-top: 5px;

    .selectCardType {
      // margin: 0 0 0 10px;
      border: 1px solid #f83130;
      border-radius: 3px;
      height: 30px;
      width: 100px;
      background: #fff;
    }

    .typeLogo {
      margin-right: 10px;
    }

    .type {
      display: inline-block;
      margin-right: 15px;
      color: grey;
    }

    .cardTable {
      display: inline-block;
      // width: 70px;
      padding: 0 5px 0 5px;
      text-align: center;
      height: 30px;
      line-height: 30px;
      box-sizing: border-box;
    }

    .selectTable {
      border: 1px solid #f83130;
      border-radius: 3px;
      color: #f83130;
    }
  }

  .record {
    height: 80px;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(110, 108, 108);
    display: flex;
    justify-content: space-between;

    .recordg_cost {
      display: inline-block;

      font-size: 12px;
    }

    p {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0px;
      padding-left: 3px;
      margin: 0px;
      font-weight: 500;
    }
  }

  .cardRecord {
    margin-top: 20px;

    margin-right: 10px;
  }

  .filterItem {
    padding: 5px 15px 5px 15px;
    display: flex;
    justify-content: space-between;
  }

  .w187 {
    width: 187px;
  }
}
</style>